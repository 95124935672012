import {useEffect, FC} from "react";
import {useRouter} from "next/router";
import {IntlProvider} from "react-intl";
import {useSelector} from "react-redux";
import dynamic from 'next/dynamic'

const ToastProvider = dynamic(() => import("./atoms/Toast/ToastProvider"))
const Layout = dynamic(() => import('./components/Layout'))
const AccountLayout = dynamic(() => import("./components/account/AccountLayout"))
import {IState} from "./types/state";
import type {AppProps} from "next/app";
import {NextComponentType, NextPageContext} from "next/dist/shared/lib/utils";
interface IProps {
    Component: NextComponentType<NextPageContext, any, any>,
    pageProps: Record<string, string>
}



const CustomWrapper: React.FC<IProps> = ({Component, pageProps}) => {
    // const list = useSelector((state: IState) => state.locale.list);
    // const locale = useSelector((state: IState) => state.locale.code);

    const customer = useSelector((state: IState) => state.customer);
    const translations = useSelector((state: IState) => state.general.translations);
    const router = useRouter();

    const isItAccountPage = router.pathname.indexOf("account") != -1;

    useEffect(() => {

        const loadData = async () => {
            if (
                isItAccountPage &&
                !customer.token &&
                !customer.authenticated

            ) {
                await router.push("/");
                // return null;
            } else if (
                isItAccountPage &&
                customer.token &&
                customer.authenticated &&
                router.pathname === "/signin"
            ) {
                await router.push("/account/profile");
                // return null;
            }
        };

        loadData();
    }, []);

    /*FIXME only use this in dev mode!!!!*/
    return typeof window !== "undefined" ? (
        //  return (
        <IntlProvider
            locale={pageProps.locale}
            messages={translations[pageProps.locale]?.translations}
        >
            <ToastProvider>
                <Layout>
                    {
                        isItAccountPage && customer.token && customer.authenticated
                            ? (
                                <AccountLayout>
                                    <Component {...pageProps} />
                                </AccountLayout>
                            )
                            : (
                                <Component {...pageProps} />
                            )
                    }


                </Layout>
            </ToastProvider>
        </IntlProvider>
    ) : <></>;
}

export default CustomWrapper;
